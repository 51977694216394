.postbanner img {
    width: 100%;
    /* height: 300px; */
    height: 100%;
    object-fit: cover;
}

/* .postbanner .postForm .postTit {
    font-size: 28px;
    font-weight: 600;
    text-transform: none;
    margin-bottom: 50px;
} */

.postbanner .postForm .primButton {
    max-width: 200px;
    margin-left: auto;
}

.postbanner .postForm {
    text-align: left;
    max-width: 1200px;
    min-width: 750px;
    width: auto;
    background-color: #fff;
    padding: 30px;
    margin: auto;
    margin-top: -125px;
    z-index: 1;
    position: relative;
    display: block;
    border-radius: 6px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 12px 0px #00000014;
}

.sub-content {
    padding-left: 30px;
}

@media (max-width: 750px) {
    .postbanner .postForm {
        min-width: auto;
    }
}
