footer .footerTag {
    background: #dadbda;
    font-size: 20px;
    color: #342d38;
    padding: 25px;
}

footer .footerTag h3 {
    letter-spacing: 2px;
    margin: 0;
    font-family: var(--trajanProBold);
    text-align: center;
}

footer ul {
    list-style: none;
    margin: 0;
    text-align: left;
    padding: 0;
}

footer .footCnt {
    padding-top: 60px;
    padding-bottom: 60px;
}

footer .footLinks {
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    text-align: left;
}

footer .footLinks h4 {
    font-size: 18px;
    color: #440e62;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

footer .footLinks li a {
    font-size: 15px;
    color: #433d46;
    width: 100%;
    display: block;
}
footer .footLinks li {
    margin-bottom: 15px;
}

footer .footLinks .footSocial {
    margin-right: 20px;
}

footer .footLinks .footSocial li a {
    color: var(--primColor);
    font-size: 15px;
}

footer .footCopyright {
    background: #342d38;
    color: #ffffff;
    font-size: 18px;
    padding: 10px;
    margin-bottom: 0;
}

@media (max-width: 768px) {
    footer .footLinks li a img {
        width: 25px;
    }
}
@media (min-width: 768px) {
    footer .footLinks h4 span {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .footLogo.d-flex {
        width: 100%;
        margin: 0 auto 30px;
    }

    .footLogo.d-flex img {
        margin: 0 auto 20px;
        width: 280px !important;
    }
}

@media (max-width: 767px) {
    .footer-sub-links {
        display: none;
    }

    .show .footer-sub-links {
        display: block;
    }

    footer .footCnt {
        padding: 15px 0;
    }

    .footLogo {
        margin: 0 auto 35px;
    }

    .footer-content {
        width: 100%;
    }

    footer .footLinks h4 {
        width: 100%;
        display: block;
        font-size: 16px;
    }

    footer .footLinks h4 span {
        float: right;
    }

    .footer-content.show h4 span {
        transform: rotate(-180deg);
    }

    footer .footCopyright {
        font-size: 11px;
        padding: 10px 15px !important;
    }

    footer .footerTag {
        padding: 15px;
    }

    footer .footerTag h3 {
        letter-spacing: 2px;
        margin: 0;
        font-size: 15px;
        line-height: 1.67;
    }
}

@media (max-width: 620px) {
    footer .footLinks li a img {
        width: 25px;
    }
    footer .footLinks h4 {
        font-size: 14px;
    }
}
