.staticPages {
    background: #fff;
    padding: 45px;
    text-align: start;
}

.staticPages h1 {
    font-size: 26px;
    text-transform: uppercase;
    font-weight: 700;
    color: #4e4e4e;
    margin-bottom: 45px;
    text-align: center;
}

.staticPages h3 {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    color: #4e4e4e;
    margin: 45px auto 20px;
    text-align: start;
}

.staticPages p {
    font-size: 16px;
    line-height: 26px;
    color: #333;
}

.staticPages ul li {
    padding-bottom: 10px;
}

.syw-wrap-pst {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: center; */
    /* align-items: center; */
    /* grid-template-columns: repeat(auto-fill, minmax(20%, 1fr)); */
    gap: 24px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 32px;
}
.syw-wrap-pst .slYrWnCard {
    width: 48%;
    display: flex;
    /* flex-direction: column; */
    align-items: flex-start;
    gap: 24px;
}
.syw-wrap-pst .imgCotnr img {
    width: 80px;
    height: auto;
}

.syw-wrap-pst .txtIfoCntr h3 {
    font-size: 1.5em;
    text-align: left;
    font-weight: 500;
}

.syw-wrap-pst .txtIfoCntr p {
    font-size: 1em;
    text-align: left;
}

@media (max-width: 900px) {
    .syw-wrap-pst {
        flex-wrap: nowrap;
        flex-direction: column;
        gap: 12px;
        /* justify-content: ; */
        align-items: center;
        margin-bottom: 32px;
    }
    .syw-wrap-pst .slYrWnCard {
        flex-direction: column;
        align-items: center;
        gap: 24px;
        width: 80%;
    }
    .syw-wrap-pst .txtIfoCntr h3 {
        text-align: center;
    }

    .syw-wrap-pst .txtIfoCntr p {
        text-align: center;
    }
}

@media (max-width: 700px) {
    .privacyWrapper p {
        margin-left: 0 !important;
        white-space: pre-wrap;
    }
}

@media (max-width: 600px) {
    .staticPages {
        padding: 20px;
    }

    .staticPages img {
        width: 100%;
    }

    .staticPages .column-1 {
        width: 100% !important;
    }

    .staticPages .column-1-img {
        width: 30%;
    }
}

.postbanner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* .postbanner .postForm .postTit {
    font-size: 28px;
    font-weight: 600;
    text-transform: none;
    margin-bottom: 50px;
} */

.postbanner .postForm .primButton {
    max-width: 200px;
    margin-left: auto;
}

.postbanner .postForm {
    text-align: left;
    max-width: 1200px;
    min-width: 750px;
    width: auto;
    background-color: #fff;
    padding: 30px;
    margin: auto;
    margin-top: -125px;
    z-index: 999;
    display: inline-block;
    border-radius: 6px;
    margin-bottom: 50px;
    box-shadow: 0px 0px 12px 0px #00000014;
}

.sub-content {
    padding-left: 30px;
}

@media (max-width: 750px) {
    .postbanner .postForm {
        min-width: auto;
    }
}
