.filterPanel {
    position: sticky;
    top: 15px;
}

/* .filterPanel .filterAcc {
    overflow-y: auto;
    height: calc(100vh - 90px);
}

.filterPanel .filterAcc::-webkit-scrollbar {
    background-color: transparent;
    width: 5px;
    height: 5px;
}

.filterPanel .filterAcc::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.filterPanel .filterAcc:hover::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
} */

/* GRID */
.productCardGrid {
    width: 100%;
    height: 100%;
    text-align: start;
    background: #fff;
    padding: 14px;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
}

.productCardGrid .pcgImg {
    width: 100%;
    height: 220px;
    position: relative;
}

.productCardGrid .pcgImg .favoriteCheck,
.productCardList .favoriteCheck {
    position: absolute;
    top: 0;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: rgba(255, 70, 70, 10%);
}

.productCardGrid .pcgImg .favoriteCheck label,
.productCardList .favoriteCheck label {
    color: #ff4646;
}

.productCardGrid .pcgImg .favoriteCheck input:checked + label,
.productCardList .favoriteCheck input:checked + label {
    color: #ff4646;
}

.productCardGrid .primButton.small button,
.productCardGrid .secButton.small button {
    font-size: 11px;
    min-width: max-content;
    width: 100%;
    padding: 5px;
    font-weight: 700;
    background: #292929;
    border: none;
    color: white;
}

.productCardGrid .productWinningStatus,
.productCardList .productWinningStatus {
    padding: 10px 30px;
    color: #fff;
    height: 30px;
    border-top-right-radius: 40px;
    border-bottom-left-radius: 40px;
    margin: 0;
    font-size: 13px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    position: absolute;
    top: 5px;
    left: 5px;
    width: auto;
    width: max-content;
    line-height: 1;
}

.productCardGrid .productWinningStatus.winning,
.productCardList .productWinningStatus.winning {
    color: #ffffff;
    background: #4caf94;
}

.productCardGrid .productWinningStatus.won,
.productCardList .productWinningStatus.won {
    background: #06b473;
}

.productCardGrid .productWinningStatus.outbid,
.productCardList .productWinningStatus.outbid {
    background: #ed8911;
    color: #ffffff;
}

.productCardGrid .productWinningStatus.lost,
.productCardList .productWinningStatus.lost {
    background: #ff7272;
}

.productCardGrid .reserveNotMet,
.productCardList .reserveNotMet {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 55px;
    right: 5px;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: rgba(114, 142, 255, 90%);
    color: #fff;
    cursor: pointer;
}

.productCardGrid .productWinningStatus:nth-child(1) {
    top: 20px;
}

.productCardGrid .pcgImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.productCardGrid .gridProdTitle {
    font-size: 16px;
    color: #231f20;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-align: center;
    font-family: var(--fontFamily);
}

.productCardGrid .gridLotInfo .gridDivider {
    padding: 0 10px;
}

.productCardGrid .gridLotDetails p {
    font-size: 13px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    height: 6ex;
    text-align: center;
    width: 100%;
}

.productCardGrid .gridTimer .material-icons {
    padding-inline-end: 10px;
}

.productCardGrid .gridTimer {
    width: 100%;
    padding: 8px 15px;
    text-align: center;
    margin-bottom: 20px;
}

/* .productCardGrid .gridTimer h6 {
    margin: 0;
    font-size: 12px;
    padding-inline-end: 5px;
} */

.productCardGrid .gridTimer p {
    margin: 0;
    font-size: 15px;
    font-weight: 600;
    padding-left: 10px;
}

.productCardGrid .gridTimer .grdTmrIcon {
    position: relative;
    height: 30px;
}

.productCardGrid .gridTimer .grdTmrIcon img {
    background: white;
    position: absolute;
    margin-top: -22px;
    padding: 8px;
    border-radius: 100px;
    left: 43%;
}

.productCardGrid .gridBidInfo {
    margin-top: 5px;
    height: 25px;
}

.productCardGrid .gridBidInfo h6 {
    margin-bottom: 0;
    font-size: 15px;
    color: #373737;
    font-family: var(--fontFamily);
}

.productCardGrid .gridBidInfo p {
    margin-bottom: 0;
    color: #616161;
    font-size: 15px;
    font-weight: 600;
    font-family: var(--fontFamily);
}

.productCardGrid .gridBidInfo p span.gridDivider {
    padding: 0 15px;
}

.productCardGrid .gridTimer p span {
    padding-inline-end: 5px;
}

.productCardGrid .gridBidBox {
    margin-top: 15px;
    height: 7ex;
}

.productCardGrid .gridBidBox .MuiFormHelperText-root.Mui-error {
    width: 100%;
    margin-inline-start: 0;
}

.productCardGrid .gridBidBox .biddingCnt > div {
    margin: 0;
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .customInput {
    margin-bottom: 5px;
}

.productCardGrid .gridBidBox .customInput input::placeholder {
    font-size: 12px;
}

.productCardGrid .gridBidBox .biddingCnt .primButton,
.productCardGrid .gridBidBox .biddingCnt .secButton {
    width: 49%;
}

.productCardGrid .gridBidBox .biddingCnt .primButton:only-child,
.productCardGrid .gridBidBox .biddingCnt .secButton:only-child {
    width: 100%;
}

.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root input {
    background: #fff;
    padding: 10px 12px;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.productCardGrid .customInput .MuiFormControl-root.MuiTextField-root {
    border: none;
}

.productCardGrid .customInput .MuiOutlinedInput-notchedOutline {
    border-color: transparent;
}

/* .productCardGrid .gridBidBox .biddingCnt.beforeLogin .primButton {
    width: 100%;
} */

/* GRID END */

/* AUCTION CARD */

.auctionCard {
    background: #fff;
    margin-bottom: 20px;
    border-radius: 5px;
    box-shadow: 0px 3px 20px #0000001f;
    border: 1px solid rgba(0, 0, 0, 0.05);
}

.auctionCard .auctionCardLt {
    width: calc(100% - 270px);
    padding: 10px;
}

.auctionCard .acImg {
    width: 100%;
    min-width: 250px;
    max-width: 250px;
    position: relative;
    margin-inline-end: 20px;
}

.auctionCard .acImg img {
    width: 100%;
    height: 200px;
    object-fit: contain;
}

.auctionCard .acActBtn .acTimer .tmrLn {
    position: relative;
    z-index: 1;
    margin-left: 15px;
    margin-right: 15px;
}

/* .auctionCard .acActBtn .acTimer .tmrLn::after {
    content: '';
    width: 100%;
    height: 2px;
    background: whitesmoke;
    position: absolute;
    top: 34%;
    left: 0;
    z-index: -1;
} */

.auctionCard .acActBtn .acTimer .tmrLn h6 {
    background: white;
    width: max-content;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.auctionCard .acActBtn .acTimer .timerIcon.material-icons-outlined {
    font-size: 45px;
    margin-bottom: 23px;
}

.auctionCard .acContent {
    margin-inline-end: 0;
    text-align: start;
    width: 100%;
}

.auctionCard .acActBtn {
    width: 263px;
    border-left: 1px solid #ebebeb;
    padding: 15px 30px;
}

.auctionCard .acActBtn .primButton {
    margin-top: 10px;
}

.auctionCard .acActBtn .primButton .MuiButton-label {
    text-transform: initial;
}

/* .liveAuction .productCardList .listActBtn {
    height: 100%;
} */

.auctionCard .acContent h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 33px;
    color: #231f20;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.auctionCard .clsngDt {
    font-size: 15px !important;
}

.auctionCard .ltCntVw h4 {
    font-size: 24px;
}

.auctionCard .ltCntVw h6 {
    font-size: 15px;
    font-weight: 600;
}

.auctionCard .ltCntVw p,
.auctionCard .clsngDt {
    font-size: 17px;
    color: #727272;
}

.auctionCard .favoriteCheck {
    width: 45px;
    height: 45px;
    background: rgba(255, 142, 142, 0.2);
    border-top-left-radius: 3px;
    position: relative;
    border-radius: 100px;
}

.auctionCard .favoriteCheck label {
    color: #b92d2d;
}

.auctionCard .favoriteCheck input:checked + label {
    color: #b92d2d;
}

.auctionCard .btnCt {
    /* max-width: 540px; */
    width: 100%;
}

.auctionCard .btnCt a {
    text-decoration: underline;
}

.auctionCard .btnCt .actnVwLk {
    color: var(--primColor);
    font-size: 20px;
}

.auctionCard .acContent p {
    font-weight: 400;
    color: #646464;
    margin-bottom: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.auctionCard .acContent .acAddress {
    height: 9ex;
}

.auctionCard .acContent .acStartDate {
    color: var(--orangeColor);
    text-transform: uppercase;
    font-weight: 700;
}

.auctionCard .acContent .acStartDate span {
    padding-inline-end: 30px;
}

.auctionCard .acContent .lotDtls .imgCntnr {
    max-width: 128px;
    width: 100%;
    height: 123px;
    margin-right: 15px;
}

.auctionCard .acContent .lotDtls .imgCntnr img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.auctionCard .acActBtn .acTimer p {
    color: #646464;
    font-size: 15px;
    font-weight: 600;
    margin-top: 5px;
    text-align: center;
}

.auctionCard .pvTimerView {
    /* display: block; */
    width: 100%;
}

.productCardList .pvTimerView {
    flex-wrap: wrap;
}

.productCardList .pvTimerView .timerIcon {
    width: 100%;
    font-size: 13px;
    font-weight: 500;
    color: #3b3e50;
    display: inline-flex;
    align-items: center;
    margin-bottom: 5px;
    justify-content: center;
}

.productCardList .pvTimerView .timerIcon .material-icons-outlined {
    margin-right: 5px;
}

.productCardList .pvTimerView {
    margin-bottom: 15px;
}

.productCardList .pvTimerView h6 {
    color: #3b3e50;
}

.productCardList .pvTimerView h6:not(:last-child) {
    margin-right: 0;
}

.productCardList .pvTimerView .dot {
    padding: 0 5px;
}

.auctionCard .acTimer > h6 {
    padding: 15px;
    color: #3b3e50;
    font-weight: 700;
    margin-bottom: 0;
    text-transform: capitalize;
    font-size: 13px;
}

.auctionCard .pvTimerView h6 {
    text-align: center;
    align-items: center;
    margin-right: 0 !important;
    font-weight: 700;
    padding-top: 8px;
    padding-bottom: 8px;
}

.auctionCard .pvTimerView h6 span {
    width: 50px;
}

.auctionCard .pvTimerView {
    align-items: flex-start;
}

.auctionCard .pvTimerView .dot {
    padding-top: 8px;
}

/* AUCTION CARD END */

/* LIST */

.productCardList {
    background: #fff;
    margin-bottom: 10px;
    width: 100%;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 10%);
    border-radius: 5px;
}

.productCardList .psListLt {
    width: 77%;
    border-right: 1px solid #e4e4e4;
    padding: 15px;
    position: relative;
}

.productCardList .psListLt .psListTag {
    font-size: 12px;
    font-weight: 500;
    color: #fff;
    background: #880aac;
    padding: 5px 10px;
    position: absolute;
    bottom: 20px;
    left: -60px;
    width: 100%;
    text-align: center;
    transform: rotate(45deg);
}

.productCardList .listLotDetails {
    margin-bottom: 20px;
}

.productCardList .listLotDetails p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 15px;
}

.productCardList .pclImg {
    width: 200px;
    min-width: 200px;
    position: relative;
    margin-inline-end: 20px;
    overflow: hidden;
}

.productCardList .pclImg a {
    position: relative;
    overflow: hidden;
    display: block;
}

.productCardList .pclImg img {
    width: inherit;
    display: block;
    height: 200px;
    object-fit: cover;
    border-radius: 3px;
}

.productCardList .favoriteCheck {
    position: absolute;
    top: 15px;
    right: 15px;
}

.productCardList .listContent {
    width: max-content;
    text-align: start;
    margin-inline-end: 0;
}

.productCardList .listActBtn {
    width: 23%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f9f9f9;
    /* background: #f5dafb; */
}

.productCardList .listActBtn .listBidBox {
    width: 100%;
}

.productCardList .listContent .listProdTitle {
    font-size: 22px;
    color: #231f20;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 15px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    padding-right: 45px;
}

.productCardList .listContent .bynwPrc span {
    font-weight: 600;
    color: var(--primColor);
}

.productCardList .listContent .vwTmrCntnr {
    color: #675d5d;
    margin-top: 5px;
}

.productCardList .listContent .sdtlsCls {
    color: #342d38;
    text-decoration: underline;
}

.productCardList .listActBox {
    width: 30%;
}

.productCardList > p {
    font-size: 14px;
    color: #646464;
    font-weight: 600;
    margin-bottom: 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.productCardList .listBidInfo h6 {
    margin: 0;
    font-size: 15px;
    color: #373737;
    padding-inline-end: 25px;
    min-width: 120px;
}

.productCardList .listTimer h6 {
    width: 100%;
    font-size: 14px;
    color: #373737;
    margin-bottom: 5px;
}

.productCardList .listTimer p {
    margin: 0;
    font-size: 15px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo p {
    margin: 0;
    font-size: 14px;
    color: var(--primColor);
    font-weight: 600;
}

.productCardList .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.productCardList .listBidInfo {
    margin-top: 5px;
}

.productCardList .listTimer p span {
    padding-inline-end: 5px;
}

.productCardList .listBidBox .biddingCnt {
    flex-wrap: wrap;
}

.productCardList .listBidBox .biddingCnt > div {
    width: 100%;
}

.productCardList .listBidBox .customInput {
    width: 100%;
    margin-bottom: 10px;
}

.productCardList .listBidBox .primButton,
.productCardList .listBidBox .secButton {
    width: 100%;
}

.productCardList .listBidBox button {
    text-transform: initial;
    font-size: 17px;
}

.productCardList button.bidDetails {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

.productCardList .qtyIptWrpr {
    width: 100%;
    /* border: 1.2px solid gray; */
    border-radius: 4px;
    margin-bottom: 15px;
    height: 45px;
    background: transparent;
}

.productCardList .qtyIptWrpr form {
    width: 100%;
}

.productCardList .boxContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: max-content;
    /* position: absolute;
    bottom: 0; */
}

/* .productCardList .boxContainer:only-child {
    display: flex;
    justify-content: flex-start;
} */

.productCardList .bynwPrc {
    font-size: 18px;
    color: rgb(125 121 127);
}

.productCardList .bynwPrc:first-child {
    margin-top: 30px !important;
}

.productCardList .qtyIptWrpr input {
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0 15px;
}

.productCardList .qtyIptWrpr input:focus {
    outline: none;
}

.productCardList .qtyIptWrpr .btnWrpr {
    display: flex;
    flex-direction: column;
    width: 35px;
}

.productCardList .qtyIptWrpr .btnWrpr img {
    margin: 5px;
    cursor: pointer;
}

.productCardList .boxContainer .box_one {
    text-align: center;
}

.productCardList .boxContainer .box_one .topContainer,
.productCardList .boxContainer .box_one .btmContainer {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    padding: 2px 5px;
}

:root {
    --wa-color: #311820;
    --ws-color: #8d0004;
    --bh-color: #ad374f;
    --iwc-color: #000000;
    --jd-color: #999999;
    --jr-color: #663d5e;
    --js-color: #711212;
    --vm-color: #000000;
    --dm-color: #460418;
    --twi-color: #5e5e5e;
}

/* Wine Rating Colors */
.WA {
    border: 1px solid var(--wa-color);
    color: var(--wa-color);
}

.productCardList .boxContainer .WA .topContainer {
    background: var(--wa-color);
    color: white;
}

.productCardList .boxContainer .WA .btmContainer {
    color: var(--wa-color);
}

.WC {
    border: 1px solid var(--ws-color);
    color: var(--ws-color);
}

.WS {
    border: 1px solid var(--ws-color);
    color: var(--ws-color);
}

.productCardList .boxContainer .WC .topContainer {
    background: var(--ws-color);
    color: white;
}

.productCardList .boxContainer .WC .btmContainer {
    color: var(--ws-color);
}

.productCardList .boxContainer .WS .btmContainer {
    color: var(--ws-color);
}

.BH {
    border: 1px solid var(--bh-color);
    color: var(--bh-color);
}

.productCardList .boxContainer .BH .topContainer {
    background: var(--bh-color);
    color: white;
}

.productCardList .boxContainer .BH .btmContainer {
    color: var(--bh-color);
}

.IWC {
    border: 1px solid var(--iwc-color);
    color: var(--iwc-color);
}

.productCardList .boxContainer .IWC .topContainer {
    background: var(--iwc-color);
    color: white;
}

.productCardList .boxContainer .IWC .btmContainer {
    color: var(--iwc-color);
}

.JD {
    border: 1px solid var(--jd-color);
    color: var(--jd-color);
}

.productCardList .boxContainer .JD .topContainer {
    background: var(--jd-color);
    color: white;
}

.productCardList .boxContainer .JD .btmContainer {
    color: var(--jd-color);
}

.JR {
    border: 1px solid var(--jr-color);
    color: var(--jr-color);
}

.productCardList .boxContainer .JR .topContainer {
    background: var(--jr-color);
    color: white;
}

.productCardList .boxContainer .JR .btmContainer {
    color: var(--jr-color);
}

.JS {
    border: 1px solid var(--js-color);
    color: var(--js-color);
}

.productCardList .boxContainer .JS .topContainer {
    background: var(--js-color);
    color: white;
}

.productCardList .boxContainer .JS .btmContainer {
    color: var(--js-color);
}

.VM {
    border: 1px solid var(--vm-color);
    color: var(--vm-color);
}

.productCardList .boxContainer .VM .topContainer {
    background: var(--vm-color);
    color: white;
}

.productCardList .boxContainer .WS .topContainer {
    background: var(--ws-color);
    color: white;
}

.productCardList .boxContainer .VM .btmContainer {
    color: var(--vm-color);
}

.DM {
    border: 1px solid var(--dm-color);
    color: var(--dm-color);
}

.productCardList .boxContainer .DM .topContainer {
    background: var(--dm-color);
    color: white;
}

.productCardList .boxContainer .DM .btmContainer {
    color: var(--dm-color);
}

.TWI {
    border: 1px solid var(--twi-color);
    color: var(--twi-color);
}

.productCardList .boxContainer .TWI .topContainer {
    background: var(--twi-color);
    color: white;
}

.productCardList .boxContainer .TWI .btmContainer {
    color: var(--twi-color);
}

/* Wine Rating Colors */

/* LIST END */

/* CART ITEM */

.cartItem {
    background: #fff;
    padding: 10px;
    padding-bottom: 25px;
    margin-bottom: 25px;
    border-bottom: 1px solid #d7dadc;
}

.cartItem .itemInfo {
    width: 75%;
}

.cartItem .pclImg {
    width: 100%;
    max-width: 150px;
    position: relative;
    margin-inline-end: 20px;
    cursor: pointer;
}

.cartItem .pclImg img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    border-radius: 5px;
}

.cartItem .listContent {
    width: max-content;
    text-align: start;
    margin-inline-end: 20px;
}

.cartItem .listActBtn {
    width: 25%;
    max-width: max-content;
}

.cartItem .listContent .listProdTitle,
.cartItem .listContent .listProdTitle a {
    font-size: 18px;
    font-weight: 600;
    color: #0e131f;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.cartItem .listContent .listLotInfo {
    margin: 5px 0;
}

.cartItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 15px;
    margin: 0;
    line-height: 25px;
    display: flex;
}

.cartItem .listContent .listLotInfo h5:nth-child(2) {
    padding-inline-start: 20px;
    border-left: 1px solid #ccc;
    margin-inline-start: 20px;
}

.cartItem .listContent .listLotInfo span:first-child {
    width: 120px;
    display: inline-block;
}

.cartItem .listContent .listLotInfo span:last-child {
    color: var(--secColor);
    font-size: 14px;
    font-weight: 700;
    padding-inline-start: 5px;
    display: inline-block;
    min-width: 68px;
    text-align: right;
}

.cartItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

.cartItem .listActBox {
    width: 30%;
}

.cartItem .listBidInfo p {
    font-size: 16px;
    color: var(--secColor);
    margin-bottom: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.cartItem .listBidInfo h6 {
    margin: 0;
    font-size: 14px;
    color: var(--secColor);
    padding-inline-end: 10px;
}

.cartItem .listBidInfo p {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
}

.cartItem .listBidInfo p span:first-child {
    color: var(--primColor);
}

.cartItem .listBidInfo p span.listDivider {
    padding: 0 15px;
}

.cartItem .listBidInfo {
    margin-top: 5px;
}

.cartItem .listTimer p span {
    padding-inline-end: 5px;
}

.cartItem .listBidBox .customInput {
    margin: 15px 0;
}

.cartItem button.removeCart {
    text-transform: initial;
    color: var(--primColor);
    margin-top: 5px;
    width: 100%;
}

.cartItem .updateCartQuantity {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 10px;
}

.cartItem .updateCartQuantity .customInput .MuiFormControl-root .MuiOutlinedInput-root {
    width: 120px;
    padding: 5px 35px;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border-radius: 50px;
    text-align: center;
    height: 40px;
}

.cartItem .updateCartQuantity input:focus {
    outline: 0;
}

.cartItem .updateCartQuantity input {
    padding: 0;
    text-align: center;
}

.cartItem .updateCartQuantity button {
    min-width: max-content;
    position: absolute;
    top: 3px;
    z-index: 10;
    color: var(--primColor);
}

.cartItem .updateCartQuantity button:first-child {
    left: 10px;
    border-radius: 50px;
}

.cartItem .updateCartQuantity button.Mui-disabled {
    color: #ccc;
}

.cartItem .updateCartQuantity button:last-child {
    right: 10px;
    border-radius: 50px;
}

/* CART ITEM END */

/* SIMILAR ITEMS */
.similarItem {
    background: #fff;
    padding: 10px;
    /* margin-bottom: 10px; */
}

.similarItem .pclImg {
    width: 25%;
    margin-inline-end: 30px;
}

.similarItem .pclImg img {
    width: 100%;
    height: 120px;
    object-fit: cover;
    border-radius: 3px;
}

.similarItem .listContent {
    text-align: start;
    width: 70%;
}

.similarItem .listContent .listProdTitle {
    font-size: 22px;
    font-weight: 600;
    color: var(--primColor);
    margin-top: 5px;
    margin-bottom: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.similarItem .listContent .listLotInfo {
    margin: 5px 0;
}

.similarItem .listContent .listLotInfo h5 {
    color: var(--secColor);
    font-size: 14px;
    margin: 0;
    line-height: 25px;
}

.similarItem .listContent .listLotInfo .gridDivider {
    padding: 0 10px;
}

/* SIMILAR ITEMS END */

.actionAfterWin {
    margin: 45px 0 5px 0;
}

.actionAfterWin .primButton,
.actionAfterWin .secButton {
    width: 100% !important;
}

/* Skeletons */

.gridViewSkeleton {
    width: 100%;
    height: 100%;
    max-width: 350px;
}

.listViewSkeleton {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1.5fr 3fr 1.5fr;
    margin: 15px 0;
    background: #f7f7f7;
}

/* .bidHistoryModal .custom-modal .modal-dialog.modal-lg {
    max-width: 500px;
} */

/* .bidHistoryModal table thead tr th:first-child,
.bidHistoryModal table tbody tr td:first-child {
    width: 55%;
} */

.bidHistoryModal table thead th,
.bidHistoryModal table tbody td {
    text-align: start;
    white-space: nowrap;
}

.customModal .bidHistoryModal .modal-dialog.modal-lg {
    max-width: 550px;
}

.productCardGrid .moreInfo button,
.productCardList .moreInfo button {
    color: var(--textColor);
    text-transform: initial;
}

.productCardGrid .moreInfo button .material-icons,
.productCardList .moreInfo button .material-icons {
    padding-inline-start: 5px;
}

/*DOWNLOAD CARD*/
.gridCard {
    width: 100%;
    box-shadow: 0 0 10px #00000026;
    background: white;
    height: 320px;
}

.gridCard .pcgImg img {
    width: 100%;
    height: 240px;
    clip-path: polygon(0 0, 100% 0, 100% 80%, 0% 96%);
    object-fit: cover;
    object-position: top;
}

.gridCard .pcgImg {
    width: 100%;
    height: 240px;
    position: relative;
}

.gridCard .pdtDtls {
    padding: 10px 20px;
}

.gridCard .pdtDtls .pdtName {
    font-size: 1.125rem;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: var(--fontFamily);
}

.gridCard .pdtDtls .cstDtls div:first-child {
    border-right: 1px solid #c1c1c1;
}

.gridCard .pdtDtls .cstDtls div p {
    font-weight: bold;
    font-size: 1.125rem;
}

.gridCard .pdtDtls .cstDtls .bdCnt {
    font-size: 0.8rem;
    color: rgb(59, 152, 228);
    text-decoration: underline;
}

.gridCard .pdtDtls {
    position: relative;
}

.dnldGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

/* .tableView .table {
    table-layout: fixed;
} */

.tableView .table thead th {
    border: none;
    background: #f0f0f0;
}

.tableView .table td {
    color: #333333;
}

.tableView .biddingCnt {
    flex-wrap: wrap;
}

.tableView .biddingCnt > div {
    width: 100%;
}

.tableView .biddingCnt .customInput {
    margin-bottom: 10px;
}

.tableView .biddingCnt .MuiInputBase-root {
    height: 40px;
}

.tableView .tableProdTitle {
    font-size: 18px;
    margin-bottom: 0;
}

.tableView .tableTimer {
    min-width: 150px;
}

.tableView .tablePrice {
    min-width: 150px;
}

.tableView .table .actionAfterWin {
    margin-top: 5px;
}

.tableView .tablePrice span {
    display: block;
}

.tableView .productWinningStatus {
    font-size: 16px;
    margin: 0;
}

.tableView::-webkit-scrollbar {
    background-color: transparent;
    width: 10px;
    height: 10px;
}

.tableView::-webkit-scrollbar-thumb {
    background-color: transparent;
}

.tableView:hover::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
}

.pdtViewWrpr .bdIptWpr .pvTimerBox {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

/* @media (min-width: 1400px) {
    .filterPanel .filterAcc {
        overflow-y: auto;
        height: 55vh;
        padding-right: 10px;
    }
} */

.productCardList .listLotNum {
    font-size: 15px;
    font-weight: 600;
    color: #4d4d4d;
}

@media (min-width: 768px) and (max-width: 1024px) {
    .auctionCard .auctionCardLt,
    .auctionCard .acActBtn {
        width: 100%;
    }
}

@media (max-width: 991px) {
    .productCardList {
        flex-wrap: wrap;
    }
}

@media (max-width: 767px) {
    .productCardList .psListLt .psListTag {
        left: -35%;
    }
    .productCardList {
        min-height: 500px;
        max-height: 600px;
        height: auto;
    }

    .auctionCard .acImg {
        margin-bottom: 30px;
    }

    .auctionCard .acImg {
        min-width: initial;
    }

    .auctionCard .acImg img {
        width: 100%;
    }

    .auctionCard .auctionCardLt {
        padding: 15px !important;
    }

    .productCardList .listActBtn .listBidBox {
        width: 100%;
        /* height: auto; */
        /* min-height: 100px; */
    }

    .productCardList .qtyIptWrpr {
        height: auto;
    }

    .productCardList .boxContainer {
        /* margin: auto; */
        margin-bottom: 15px;
    }

    .productCardList {
        width: 47%;
        float: left;
        margin: 10px 5px;
        max-height: unset;
    }

    .ld-wrap-xs {
        width: 100%;
    }

    .productCardList .listContent .vwTmrCntnr {
        font-size: 12px;
    }

    .productCardList .psListLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .productCardList .listBidStatus > h4 {
        position: relative;
    }

    .productCardList .pclImg {
        width: 100%;
        height: 200px;
        /* padding-top: 100%; */
        margin-inline-end: 0;
        /* height: fit-content; */
        /* aspect-ratio: 1/1; */
    }

    .productCardList .pclImg img {
        height: 100%;
        width: 200px;
        object-fit: contain;
        margin-left: auto;
        margin-right: auto;
    }

    .productCardList .listContent .listProdTitle {
        padding-left: 60px;
        margin-top: 0;
        font-size: 15px;
        text-align: center;
        padding-right: 15px;
        padding-left: 15px;
    }

    .productCardList .listContent {
        width: 100%;
    }

    .auctionCard .acActBtn {
        padding: 15px;
        margin: 0 auto;
    }

    .auctionCard .acContent h2 {
        font-size: 20px;
    }

    .bynwPrc {
        font-size: 16px;
    }

    .auctionCard .auctionCardLt {
        width: 100%;
    }

    .auctionCard {
        background: #fff;
        margin-bottom: 20px;
        border-radius: 5px;
        box-shadow: 0px 3px 15px rgb(0 0 0 / 5%);
        border: 1px solid rgba(0, 0, 0, 0.05);
        flex-wrap: wrap;
    }

    .cartItem {
        flex-wrap: wrap;
        width: 48%;
    }

    .cartItem .itemInfo {
        width: 100%;
        flex-wrap: wrap;
    }

    .cartItem .pclImg {
        max-width: none;
        margin-inline-end: 0;
    }

    .cartItem .pclImg img {
        height: 200px;
    }

    .cartItem .listContent {
        width: 100%;
        margin-inline-end: 0;
    }

    .cartItem .listActBtn {
        width: 100%;
    }

    .productCardList .listActBtn {
        width: 100%;
    }

    .listBidBox {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .listBidBox > div {
        width: 32%;
    }

    .productCardList .listBidBox button {
        font-size: 14px;
    }

    .productCardGrid .gridBidBox,
    .productCardGrid .gridBidInfo,
    .productCardGrid .gridLotDetails p {
        height: auto;
    }

    .gridViewSkeleton {
        max-width: none;
    }

    .auctionCard {
        flex-wrap: wrap;
    }

    .auctionCard .auctionCardLt {
        width: 100%;
        flex-wrap: wrap;
    }

    .auctionCard .acImg {
        max-width: initial;
        margin-inline-end: 0;
    }

    .auctionCard .acContent {
        width: 100%;
        margin-inline-end: 0;
    }

    .auctionCard .acActBtn {
        width: 100%;
    }

    .auctionCard .acTimer {
        margin: 10px 0;
    }

    .auctionCard .pvTimerView {
        display: flex;
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
    }

    .auctionCard .pvTimerView h6 {
        padding-left: 0;
        font-size: 16px;
    }

    .auctionCard .pvTimerView h6 span {
        width: auto;
        padding-right: 10px;
    }

    .auctionCard .acActBtn .acTimer p {
        margin: 0;
        margin-inline-start: 10px;
    }

    .auctionCard .acContent h2 {
        font-size: 16px;
        line-height: 20px;
    }

    .auctionCard .acContent p {
        font-size: 13px;
        margin-bottom: 2px;
    }

    .cartItem .pclImg img {
        height: 150px;
    }

    .cartItem .itemInfo {
        width: 100%;
    }

    .productCardGrid .gridProdTitle {
        font-size: 16px;
    }

    /* .productCardGrid .gridTimer h6, */
    .productCardGrid .gridBidInfo h6 {
        font-size: 13px;
    }

    .search .searchResults .productCardGrid,
    .dashboard .productCardGrid {
        width: 100%;
    }

    .productCardGrid .gridBidBox .customInput,
    .productCardGrid .gridBidBox .customInput .MuiFormControl-root.MuiTextField-root {
        height: 45px;
    }

    /* .productCardGrid .gridBidBox .customInput input {
        height: 100%;
    } */
    .productCardGrid .gridBidBox .primButton,
    .productCardGrid .gridBidBox .secButton {
        width: 49%;
    }

    .similarItem .listContent .listProdTitle {
        font-size: 15px;
    }

    .similarItem .pclImg {
        width: 30%;
        margin-inline-end: 10px;
    }

    .cartItem {
        width: 100%;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem .listActBtn {
        display: flex;
        max-width: none;
        justify-content: space-between !important;
    }

    .cartItem button.removeCart {
        width: max-content;
    }

    .cartItem .listContent .listLotInfo h5 {
        width: 100%;
        justify-content: space-between;
    }

    .filterPanel {
        position: relative;
        top: unset;
    }

    .cartItem .listContent .listLotInfo h5 span.text-left {
        text-align: right !important;
    }
}

@media (max-width: 600px) {
    .productCardList .bynwPrc {
        font-size: 14px;
    }

    .productCardList .listContent .listProdTitle {
        min-height: 45px;
        padding-top: 8px;
    }

    /* .productCardList {
        min-height: 500px;
        max-height: 500px;
    } */
}

@media (max-width: 479px) {
    .productCardList {
        width: 100%;
        margin: 10px 5px;
    }

    .pvTimerView h6 {
        font-size: 16px;
    }

    .cartItem .listContent .listLotInfo {
        margin: 5px 0;
        flex-wrap: wrap;
    }

    .cartItem .listContent .listLotInfo h5:nth-child(2) {
        padding-inline-start: 0;
        border-left: none;
        margin-inline-start: 0;
    }

    .auctionCard .acContent h2 {
        font-size: 14px;
    }

    .auctionCard .acContent p {
        font-size: 12px;
    }

    .auctionCard .acContent {
        margin-bottom: 10px;
    }

    .listBidBox > div {
        width: 48%;
        max-width: 48%;
    }

    .listBidBox > div:last-child {
        width: 100%;
        max-width: 100%;
    }

    .bynwPrc {
        font-size: 14px;
    }

    .auctionCard .acImg {
        margin-bottom: 10px;
    }
}

@media (max-width: 450px) {
    .productCardList {
        width: 100%;
    }

    .productCardList {
        max-height: fit-content;
        height: auto;
    }
}
