.slick-next:before,
.slick-prev:before {
    color: black;
}

@font-face {
    font-family: 'Trajan Pro Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Trajan Pro Regular'),
        url('./assets/fonts/Trajon/TrajanPro-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Trajan Pro Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Trajan Pro Bold'), url('./assets/fonts/Trajon/TrajanPro-Bold.woff') format('woff');
}

body {
    margin: 0;
    font-family: var(--fontFamily), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--backgroundColor);
    color-adjust: exact !important;
    -webkit-print-color-adjust: exact !important;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.noUnderline {
    text-decoration: none !important;
}

.noUnderline:hover {
    text-decoration: none !important;
}

.initalLoadingCnt {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursorPointer {
    cursor: pointer;
}

h1 {
    font-size: 45px;
}

h2 {
    font-size: 28px;
}

h4 {
    font-size: 18px;
}

a {
    color: var(--primColor);
    font-weight: 500;
}

.customContainer {
    padding-inline-start: 30px !important;
    padding-inline-end: 30px !important;
    width: 100% !important;
    max-width: 1900px;
    margin-left: auto !important;
    margin-right: auto !important;
}

div:focus,
button:focus {
    outline: 0;
}

.MuiButton-label {
    font-family: var(--fontFamily), 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
        'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

.MuiBadge-badge {
    background-color: rgb(168 119 158) !important;
    color: #ffffff !important;
}

.MuiSwitch-switchBase.Mui-checked {
    color: var(--primColor) !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: var(--primColor) !important;
}

.MuiTooltip-popper .MuiTooltip-tooltip {
    font-size: 12px;
    background: #2b2b2be0;
}

.emptySoon {
    color: rgb(148 19 26) !important;
}

.MuiTabScrollButton-root {
    background: #7b6d8112;
}

.MuiTabScrollButton-root .MuiSvgIcon-root {
    fill: var(--primColor);
}

.customInput,
.customSelect,
.MuiFormControl-root.customDatepicker,
.customTextArea {
    margin-bottom: 30px;
    /* width: 100%; */
}

.customTextArea .MuiFormControl-root {
    width: 100%;
}

.MuiFormControl-root.customDatepicker {
    width: 100%;
}

.customSelect .MuiFormControl-root {
    width: 100%;
}

.customInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutlin,
.customSelect .MuiSelect-root.MuiSelect-select.Mui-focused {
    border-color: var(--secColor);
}

.customInput .MuiFormLabel-root.Mui-focused,
.customSelect .MuiFormLabel-root.Mui-focused {
    color: var(--secColor);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--secColor) !important;
    border-width: 1px !important;
}

.customInput .MuiFormControl-root.MuiTextField-root,
.customSelect .MuiFormControl-root.MuiTextField-root {
    width: 100%;
    margin: 0;
    text-align: start;
}

.MuiInputBase-root,
.MuiTypography-body1 {
    font-family: var(--fontFamily) !important;
}

.cursorDecoy {
    cursor: pointer;
}

.customModal {
    z-index: 1500 !important;
}

.header .headLogo img {
    width: 160px;
}

.search .searchLt,
.dashboard .dashboardLt {
    max-width: 250px;
    width: 100%;
    margin-inline-end: 25px;
}

.search .searchLt {
    position: sticky;
    top: 10px;
}

.dashboard .dashboardLt .sideNav {
    position: sticky;
    top: 10px;
}

.search .searchRt,
.dashboard .dashboardRt {
    width: 100%;
    width: -webkit-fill-available;
    max-width: calc(100% - 250px);
}

.search .searchResults.Grid,
.dashboard .searchResults.Grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    gap: 15px;
}

.toggleRespDrawer.MuiButton-root {
    display: none;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root {
    min-width: max-content;
    color: #8e8e8e;
}

.search .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active,
.dashboard .searchMiscFilters .MuiButtonBase-root.MuiButton-root.active {
    color: var(--primColor);
}

.search .searchMiscFilters .customSelect,
.dashboard .searchMiscFilters .customSelect {
    margin-bottom: 0;
    min-width: 150px;
    margin-inline-start: 25px;
}

.moveBack {
    color: #a6adb4;
}

.moveBack .material-icons {
    color: #979797;
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #dfdfdf;
    margin-inline-end: 5px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.customBreadcrumbs ol {
    width: max-content;
}

.customBreadcrumbs a {
    color: #797979;
    text-transform: capitalize;
}

.customBreadcrumbs p {
    text-transform: capitalize;
}

.dpTable table {
    color: #212529;
}

.dpTable table thead th {
    background: #343a40;
    color: #fff;
}

.dpTable table .MuiFormControlLabel-root {
    margin: 0;
}

.dpTable table td.credit {
    color: #4caf50;
    font-weight: 500;
    display: flex;
}

.dpTable table td.debit {
    color: #f44336;
    font-weight: 500;
    display: flex;
}

.dpTable table td > .material-icons {
    padding-inline-end: 5px;
}

.dpTable .table th {
    border: none;
}

.dpTable .table td,
.dpTable .table th {
    vertical-align: middle;
}

.searchSkeletonBody {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
}

.searchSkeletonBody > div {
    box-shadow: 0 3px 20px rgb(0 0 0 / 10%);
}

.searchSkeletonBody .skeletonWrapper {
    background: #fff;
}

.material-icons.rtl,
.rtlImage {
    transform: rotateY(180deg);
}

.loadingCnt {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 15px;
}

.noGrid .loadingCnt {
    display: block;
}

.userInitials.small {
    width: 30px;
    height: 30px;
    font-size: 14px;
    background: #3d3d3d;
    border-radius: 50%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.userInitials.large {
    width: 90px;
    height: 90px;
    font-size: 28px;
    font-weight: 700;
    background: #f1f3f6;
    border-radius: 50%;
    color: #9ca8b9;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.regDivider {
    margin-bottom: 35px;
}

.rightDrawer .MuiPaper-root.MuiDrawer-paper {
    width: 50vmax;
    max-width: 800px;
    padding: 20px;
}

.pvTimerView {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pvTimerView h6 {
    font-size: 20px;
    font-weight: 700;
    color: #0a0a0a;
    margin-bottom: 0;
}

.pvTimerView h6:not(:last-child) {
    margin-right: 25px;
}

.pvTimerView h6 span {
    display: block;
    font-size: 12px;
    font-weight: 500;
    color: black;
    margin-bottom: 5px;
    margin-top: 5px;
}

.color-primary {
    color: var(--primColor);
}

.pvLoader .loadingCnt {
    margin-top: 20px;
}

.tableLoader .loadingCnt,
.listLoader .loadingCnt,
.pvLoader .loadingCnt {
    display: block;
}

.listLoader .loadingCnt .skeletonWrapper,
.pvLoader .loadingCnt .skeletonWrapper {
    box-shadow: none;
}

.noGridLoader .loadingCnt {
    display: block;
}

.auctions .listViewSkeleton .skeletonWrapper {
    box-shadow: none;
}

.noGridLoader .skeletonWrapper {
    box-shadow: none;
}

.search .listViewSkeleton {
    background: #fff;
}

.fltrLeftDrawer .MuiDrawer-paper {
    max-width: 400px;
    width: 100%;
    padding: 15px;
}

.purchasedTable tbody tr td:not(:first-child) {
    text-align: right !important;
    padding-right: 15px;
}

.purchasedTable thead tr th:not(:first-child) {
    padding-right: 15px;
    text-align: right !important;
}

.popupActionBtn .primButton {
    margin-right: auto !important;
    margin-left: 0 !important;
}

.customErrCnt {
    position: relative;
    height: 40px;
    width: 100%;
    overflow: hidden;
    display: none;
    transition: 1000ms all ease-in-out;
    margin: -10px 0 10px;
}

.customErrCnt.visible {
    display: block;
    visibility: visible;
    opacity: 1;
}

.customErrBox {
    background: #d32f2f;
    color: #fff;
    padding: 8px;
    font-size: 15px;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    position: absolute;
    margin: 0;
    width: 100%;
    left: 0;
    top: 100px;
    animation: mouse 0.8s ease 1 normal;
}

.customErrBox .material-icons-outlined {
    margin-right: 8px;
}

.customErrCnt.visible .customErrBox {
    top: 0;
}

.cartSkeletonBody .listViewSkeleton {
    background: transparent;
}

.cartSkeletonBody .skeletonWrapper {
    box-shadow: none;
}

.postbanner h4 {
    font-size: 24px;
}

.addCardModalActions {
    display: flex;
    justify-content: flex-start;
}

.addCardModalActions button {
    min-width: 150px;
}

@keyframes mouse {
    0% {
        top: 100px;
    }

    50% {
        top: 50px;
    }

    100% {
        top: 0px;
    }
}

/* @media (max-height: 780px) { */
.search .searchLt .deskFilter .filterPanel {
    z-index: 100;
}
.search .searchLt .deskFilter .filterPanel {
    overflow-y: auto;
    height: 100vh;
}
.filterPanel::-webkit-scrollbar {
    width: 6px;
}

.filterPanel::-webkit-scrollbar-track {
    background: #e7e7e7;
}

.filterPanel::-webkit-scrollbar-thumb {
    background: rgba(72, 42, 66, 0.8);
    border-radius: 6px;
}

.filterPanel::-webkit-scrollbar-thumb:hover {
    background: #555;
}
/* } */

@media (max-width: 1145px) {
    .search .searchLt,
    .dashboard .dashboardLt {
        max-width: 250px;
    }

    .search .searchMiscFilters .customSelect,
    .dashboard .searchMiscFilters .customSelect {
        margin-inline-start: 15px;
    }
}

@media (max-width: 1024px) {
    .toggleRespDrawer.MuiButton-root {
        display: inline-flex;
    }
    /* .filterPanel .filterAcc {
        height: auto !important;
    } */
    .search .searchLt,
    .dashboard .dashboardLt {
        display: none;
    }

    .search .searchRt,
    .dashboard .dashboardRt {
        width: 100%;
        max-width: none;
    }

    .responsiveFilterDrawer .MuiPaper-root.MuiDrawer-paper {
        padding: 25px 15px;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        width: 100%;
        min-height: 300px;
        max-height: 90vh;
    }

    .responsiveFilterDrawer .filterPanel .fpTitle {
        text-transform: uppercase;
        margin-bottom: 0;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root {
        width: 100%;
        padding: 10px 0;
        max-height: 45vh;
        overflow-y: auto;
        padding-top: 20px;
    }

    .responsiveFilterDrawer .filterPanel .MuiAccordionDetails-root small {
        padding: 10px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .customCheckbox {
        width: 100%;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 25%;
        z-index: 100;
    }

    .responsiveFilterDrawer .filterPanel .filterAcc .MuiAccordionSummary-root.Mui-expanded {
        background: #fff2e7;
        border-color: rgb(25 157 229 / 20%) !important;
    }

    .customBreadcrumbs ol {
        width: 100%;
    }

    .filterPanel .priceSlider .MuiSlider-root {
        width: 80%;
        margin-left: 20px;
    }
}

input[value='']::-webkit-datetime-edit {
    color: transparent;
}

input:focus::-webkit-datetime-edit {
    color: #000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: initial;
}

/* @media (max-width: 991px) {
    .customContainer {
        padding-inline-start: 30px !important;
        padding-inline-end: 30px !important;
    }
} */

.MuiDivider-root {
    /* background-color: rgb(149 208 242 / 55%) !important; */
}

@media (max-width: 992px) {
    .slick-next:before,
    .slick-prev:before {
        font-size: 30px;
    }

    .slick-next,
    .slick-prev {
        width: 30px;
        height: 30px;
        z-index: 999;
    }

    .slick-prev {
        left: -15px;
    }

    .slick-next {
        right: -15px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }
}

@media (max-width: 767px) {
    .customCheckbox .MuiListItem-gutters {
        padding-left: 9px;
        padding-right: 9px;
    }

    .registration .plBox .MuiFormControlLabel-root {
        margin: 0;
    }

    .search .searchMiscFilters {
        align-items: flex-start;
    }
}

@media (max-width: 700px) {
    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }
}

@media (max-width: 600px) {
    .pvTimerView h6 {
        font-size: 16px;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 50%;
    }

    .toggleRespDrawer.MuiButtonBase-root {
        width: max-content;
        flex-wrap: nowrap;
    }

    .responsiveFilterDrawer .filterPanel .filterCheck .MuiFormGroup-root.MuiFormGroup-row,
    .responsiveFilterDrawer .filterPanel .filterCheck .RadioBox .MuiFormControlLabel-root {
        width: 100%;
    }

    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
        width: 100% !important;
    }

    .rightDrawer .MuiPaper-root.MuiDrawer-paper {
        width: 90%;
    }
}

@media (max-width: 445px) {
    .customContainer {
        padding-inline-start: 15px !important;
        padding-inline-end: 15px !important;
    }

    .pagination-wrapper h6 {
        font-size: 14px;
    }

    .fltrLeftDrawer .MuiDrawer-paper {
        max-width: 90vw;
    }
}

@media (max-width: 400px) {
    .addCardModalActions {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        width: 100%;
        padding: 0 15px;
    }
    .addCardModalActions .primButton,
    .addCardModalActions button {
        width: 100%;
        max-width: initial;
    }
}

@media print {
    .noPrint {
        display: none !important;
    }
}
