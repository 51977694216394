.pdtViewWrpr .pdtImgContainer {
    width: 100%;
    /* height: 422px; */
    position: relative;
    max-width: 100%;
}

.pdtViewWrpr .pdtImgContainer .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 500px;
}

.pdtViewWrpr .pdtImgContainer img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.pdtViewWrpr .image-gallery-content.fullscreen .image-gallery-slide .image-gallery-image {
    max-height: calc(100vh - 80px);
}

.pdtViewWrpr .pdtImgContainer .timerContainer {
    position: absolute;
    height: 40px;
    width: 100%;
    top: 0;
    left: 0;
    background: var(--primColor);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.pdtViewWrpr .pvSku span {
    width: 1px;
    height: 15px;
    background: #333;
    display: inline-block;
    margin: 0 10px;
}

.pdtViewWrpr .pdtImgContainer .timerContainer h4 {
    font-size: 17px;
    margin-bottom: 0;
}

.pdtViewWrpr .backButton {
    text-transform: initial;
    font-size: 18px;
}

.pdtViewWrpr .adtnlTxtInfo {
    padding: 25px 0;
}

.pdtViewWrpr .adtnlTxtInfo div h5 {
    font-size: 20px;
    font-weight: 400;
}

.pdtViewWrpr .pvFavorites {
    background: #f3e5e6;
    width: max-content;
    font-weight: 500;
    /* height: 50px; */
}

.pdtViewWrpr .pvFavorites label {
    height: inherit;
    padding: 8px 16px;
    color: #b50610;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
}

.pdtViewWrpr .pvFavorites label span {
    display: inline-block;
    margin-right: 10px;
}

.pdtViewWrpr .pvFavorites input:checked + label {
    color: #b50610;
}

.pdtViewWrpr .image-gallery-thumbnail.active,
.pdtViewWrpr .image-gallery-thumbnail:hover,
.pdtViewWrpr .image-gallery-thumbnail:focus {
    border: none;
    opacity: 0.5;
}

.pdtViewWrpr .image-gallery-thumbnail {
    border: none;
    height: 80px;
    opacity: 0.5;
}

.pdtViewWrpr .image-gallery-thumbnail > span {
    height: inherit;
}

.pdtViewWrpr .image-gallery-thumbnail.active {
    border: none;
    opacity: 1;
}

.pdtViewWrpr .adtnlTxtInfo div p {
    font-size: 20px;
    color: #663d5e;
    margin-bottom: 0;
    font-weight: 600;
}

.pdtViewWrpr .adtnlTxtInfo div a {
    color: #3275a7;
    font-size: 18px;
    text-decoration: underline;
}

.pdtViewWrpr .pdtTtile {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 0;
}

.pdtViewWrpr .pdtSku {
    color: #6d6d6d;
    font-weight: 600;
}

.pdtViewWrpr .boxContainer.exChgPos {
    margin: 0;
    margin-left: 25px;
}

.pdtViewWrpr .boxContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: max-content;
    margin: 0 auto;
}

.pdtViewWrpr .boxContainer .box_one {
    text-align: center;
}

.pdtViewWrpr .boxContainer .box_one .topContainer,
.pdtViewWrpr .boxContainer .box_one .btmContainer {
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    padding: 2px 12px;
}

.pdtViewWrpr .pvCond p {
    font-size: 18px;
    font-weight: 600;
}

:root {
    --wa-color: #311820;
    --ws-color: #8d0004;
    --bh-color: #ad374f;
    --iwc-color: #000000;
    --jd-color: #999999;
    --jr-color: #663d5e;
    --js-color: #711212;
    --vm-color: #000000;
    --dm-color: #460418;
    --twi-color: #5e5e5e;
}

/* Wine Rating Colors */
.WA {
    border: 1px solid var(--wa-color);
    color: var(--wa-color);
}

.pdtViewWrpr .boxContainer .WA .topContainer {
    background: var(--wa-color);
    color: white;
}

.pdtViewWrpr .boxContainer .WA .btmContainer {
    color: var(--wa-color);
}

.WC {
    border: 1px solid var(--ws-color);
    color: var(--ws-color);
}

.pdtViewWrpr .boxContainer .WC .topContainer {
    background: var(--ws-color);
    color: white;
}

.pdtViewWrpr .boxContainer .WC .btmContainer {
    color: var(--ws-color);
}

.pdtViewWrpr .boxContainer .WS .topContainer {
    background: var(--ws-color);
    color: white;
}

.pdtViewWrpr .boxContainer .WS .btmContainer {
    color: var(--ws-color);
}

.BH {
    border: 1px solid var(--bh-color);
    color: var(--bh-color);
}

.pdtViewWrpr .boxContainer .BH .topContainer {
    background: var(--bh-color);
    color: white;
}

.pdtViewWrpr .boxContainer .BH .btmContainer {
    color: var(--bh-color);
}

.IWC {
    border: 1px solid var(--iwc-color);
    color: var(--iwc-color);
}

.pdtViewWrpr .boxContainer .IWC .topContainer {
    background: var(--iwc-color);
    color: white;
}

.pdtViewWrpr .boxContainer .IWC .btmContainer {
    color: var(--iwc-color);
}

.JD {
    border: 1px solid var(--jd-color);
    color: var(--jd-color);
}

.pdtViewWrpr .boxContainer .JD .topContainer {
    background: var(--jd-color);
    color: white;
}

.pdtViewWrpr .boxContainer .JD .btmContainer {
    color: var(--jd-color);
}

.JR {
    border: 1px solid var(--jr-color);
    color: var(--jr-color);
}

.pdtViewWrpr .boxContainer .JR .topContainer {
    background: var(--jr-color);
    color: white;
}

.pdtViewWrpr .boxContainer .JR .btmContainer {
    color: var(--jr-color);
}

.JS {
    border: 1px solid var(--js-color);
    color: var(--js-color);
}

.pdtViewWrpr .boxContainer .JS .topContainer {
    background: var(--js-color);
    color: white;
}

.pdtViewWrpr .boxContainer .JS .btmContainer {
    color: var(--js-color);
}

.VM {
    border: 1px solid var(--vm-color);
    color: var(--vm-color);
}

.pdtViewWrpr .boxContainer .VM .topContainer {
    background: var(--vm-color);
    color: white;
}

.pdtViewWrpr .boxContainer .VM .btmContainer {
    color: var(--vm-color);
}

.DM {
    border: 1px solid var(--dm-color);
    color: var(--dm-color);
}
.pdtViewWrpr .boxContainer .DM .topContainer {
    background: var(--dm-color);
    color: white;
}
.pdtViewWrpr .boxContainer .DM .btmContainer {
    color: var(--dm-color);
}

.TWI {
    border: 1px solid var(--twi-color);
    color: var(--twi-color);
}
.pdtViewWrpr .boxContainer .TWI .topContainer {
    background: var(--twi-color);
    color: white;
}
.pdtViewWrpr .boxContainer .TWI .btmContainer {
    color: var(--twi-color);
}

/* Wine Rating Colors */

.pdtViewWrpr .rvwHdr {
    color: #6d6d6d;
    font-weight: 600;
    margin-top: 25px;
    margin-bottom: 5px;
    font-size: 18px;
}

.pdtViewWrpr .dscptnTxt > * {
    font-size: 18px;
    font-weight: 500;
}

.pdtViewWrpr .smrBtn {
    color: #3275a7;
    text-decoration: underline;
}

.pdtViewWrpr .prcngInfoVw {
    margin-top: 25px;
}

.pdtViewWrpr .prcngInfoVw h2 {
    font-size: 24px;
    font-weight: 600;
}

.pdtViewWrpr .prcngInfoVw h2 small {
    font-size: 20px;
}

.pdtViewWrpr .prcngInfoVw p {
    font-size: 18px;
    margin: 0;
}

.pdtViewWrpr .prcngInfoVw p span {
    font-weight: 600;
    margin-right: 10px;
}

.pdtViewWrpr .btnInfoStack label {
    font-size: 20px;
    margin: 0;
}

.pdtViewWrpr .btnInfoStack input {
    width: 100px;
    height: 50px;
    padding: 0 15px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    opacity: 1;
}

.pdtViewWrpr .qtyIptWrpr {
    /* width: 105px; */
    border: 1.2px solid gray;
    border-radius: 4px;
    height: 50px;
    background: transparent;
    margin-bottom: 10px;
}

.colorPrimary {
    color: var(--primColor) !important;
}

.pdtViewWrpr .qtyIptWrpr input {
    height: 100%;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0 15px;
}

.pdtViewWrpr .qtyIptWrpr input:focus {
    outline: none;
}

.pdtViewWrpr .qtyIptWrpr .btnWrpr {
    display: flex;
    flex-direction: column;
    width: 35px;
}

.pdtViewWrpr .qtyIptWrpr .btnWrpr img {
    margin: 5px;
    cursor: pointer;
}

.pdtViewWrpr .adFvBtn {
    background: #f3e5e6;
    color: #b50610;
    height: 50px;
}

.pdtViewWrpr .btnInfoStack button {
    padding-left: 25px;
    padding-right: 25px;
}

.pdtViewWrpr .timerCon {
    color: #8d030a;
}

.pdtViewWrpr .bdngCntnr {
    margin-top: 29px;
}

.pdtViewWrpr .startBid {
    color: #524c54;
    font-weight: 600;
}

.pdtViewWrpr .bdIptWpr {
    background: #f9f9f9;
    padding: 30px 25px;
    margin-top: 10px;
    max-width: 100%;
}

.pdtViewWrpr .bdIptWpr.limitWidth {
    max-width: max-content;
    min-width: 500px;
}

.pdtViewWrpr .bdIptWpr .bdPlcWrpr .primButton button {
    background: #433d46;
}

.pdtViewWrpr .bdIptWpr .pvTimerBox .material-icons-outlined {
    padding: 0 10px;
    margin-left: 0 !important;
}

.priceTitle {
    font-size: 18px;
    font-weight: 700;
    color: #524c54;
}

.qtyAvbl {
    margin: 5px auto;
    width: 100%;
}

.productViewPage .loadingCnt {
    display: block;
    margin-top: 45px;
}

.pdtViewWrpr .loadingCnt .skeletonWrapper {
    box-shadow: none;
}

.qtyAvbl .MuiButton-label {
    text-transform: initial;
    font-size: 15px;
    font-weight: 600;
}

.qtyPopover .MuiPopover-paper {
    min-width: 300px;
}

.qtyPopover .MuiPopover-paper table {
    margin-bottom: 0;
}

.pdtViewWrpr .bdIptWpr .orBtnStn {
    margin-top: 150px;
    font-size: 20px;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.pdtViewWrpr .iptWpr input {
    height: 50px;
    border-radius: 4px;
    border: 1px solid gray;
    background: transparent;
}

.pdtViewWrpr .bdIptWpr .biddingCnt {
    flex-wrap: wrap;
    margin-top: 10px;
}

.pdtViewWrpr .bynwRpr .biddingCnt {
    margin-top: 35px;
}

.pdtViewWrpr .bdIptWpr .biddingCnt div,
.pdtViewWrpr .bdIptWpr .biddingCnt .primButton {
    width: 100%;
}

.pdtViewWrpr .bdIptWpr .biddingCnt .primButton button,
.pdtViewWrpr .bdIptWpr .biddingCnt .secButton button {
    height: 50px;
}

.pdtViewWrpr .bdIptWpr .biddingCnt .customInput,
.pdtViewWrpr .bdIptWpr .biddingCnt .customSelect {
    margin-bottom: 15px;
}

.pdtViewWrpr .bdIptWpr .biddingCnt .customInput .MuiOutlinedInput-inputMarginDense {
    padding-top: unset;
    padding-bottom: unset;
    padding: 18.5px 14px;
}

.pdtViewWrpr .pvBidStatus {
    padding: 10px 0;
    background: #fff;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 10;
}

.pdtViewWrpr .pvBidStatus h4 {
    padding: 15px;
    color: #fff;
    height: 45px;
    width: 100%;
    margin: 0;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.25px;
    border-radius: 0;
    margin-bottom: 10px;
}

.pdtViewWrpr .pvBidStatus h4.winning {
    color: #ffffff;
    background: #4caf94;
}

.pdtViewWrpr .pvBidStatus h4.won {
    background: #06b473;
}

.pdtViewWrpr .pvBidStatus h4.outbid {
    background: #ed891163;
    color: #844800;
}

.pdtViewWrpr .pvBidStatus h4.lost {
    background: #ff7272;
}

@media (max-width: 768px) and (min-width: 768px) {
    .pro-view-left {
        /* display: flex; */
    }
}

@media (max-width: 767px) {
    .pdtViewWrpr .pdtImgContainer {
        max-width: 100%;
        width: 100%;
        /* height: 300px; */
    }

    .pdtViewWrpr .bdIptWpr .orBtnStn {
        margin-top: 20px;
    }

    .pro-title-xs {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .pdtViewWrpr .prcngInfoVw,
    .pdtViewWrpr .bdngCntnr {
        margin-top: 0;
    }

    .bdIptWpr h3 {
        font-size: 1.25rem;
        font-weight: bold;
    }

    .pdtViewWrpr .bdIptWpr .orBtnStn {
        padding-bottom: 0;
        font-size: 20px;
    }

    .pdtViewWrpr .dscptnTxt {
        font-size: 12px;
    }
}

@media (max-width: 600px) {
    .dibHisty {
        flex-direction: column;
        align-items: flex-start !important;
    }

    .pdtViewWrpr .boxContainer.exChgPos {
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 15px;
        max-width: 190px;
    }

    .pdtViewWrpr .bdIptWpr.limitWidth {
        max-width: 100%;
        min-width: unset;
    }

    .pdtViewWrpr .pvBidStatus h4.winning {
        margin-top: 15px;
    }

    .pdtViewWrpr .pvBidStatus {
        padding: 0;
    }

    .pdtViewWrpr .pvFavorites {
        margin-bottom: 25px;
    }

    .pro-view-left {
        padding: 0;
    }

    .pdtViewWrpr .pdtTtile {
        font-size: 22px;
    }

    .pdtViewWrpr .bdIptWpr {
        background: #fff;
        padding: 0;
        margin-top: 30px;
    }

    .pdtViewWrpr .bdIptWpr .orBtnStn {
        padding: 20px 0;
    }

    .pdtViewWrpr .boxContainer {
        width: 100%;
        margin-right: 0 !important;
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr 1fr !important;
        margin: 10px 0;
    }

    .pdtViewWrpr .pdtImgContainer {
        height: auto;
    }

    .pdtViewWrpr .adtnlTxtInfo {
        padding-top: 0;
    }
}
