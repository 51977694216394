.loader {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(15px);
    background: #2196f31f;
    transition: 200ms all ease-in-out;
    user-select: none;
}

/* CHECKOUT */

.checkout .loadingCnt {
    grid-template-columns: 1fr;
}

.checkoutSkeletonBody .checkoutSkeletonCnt {
    display: grid;
    grid-template-columns: 3.8fr 1.8fr;
    gap: 15px;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt .cscItem {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 15px;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt .cscItem > .skeletonWrapper:first-child {
    height: 200px;
    width: 70%;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt .cscItem .cscInfo > .skeletonWrapper {
    width: 200px;
    box-shadow: none;
}

.checkoutSkeletonBody
    .checkoutSkeletonCnt
    > .cscLt
    .cscItem
    .cscInfo
    > .skeletonWrapper:first-child {
    margin-bottom: 20px;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt > .skeletonWrapper {
    margin: 20px 0;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt .apptInfo {
    max-width: 500px;
    width: 100%;
    margin: 40px 0 20px 0;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt .apptInfo .skeletonWrapper:first-child {
    width: 200px;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt .apptInfo .skeletonWrapper:nth-child(2) {
    width: 300px;
}

.checkoutSkeletonBody .checkoutSkeletonCnt > .cscLt .apptDate {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
    gap: 15px;
}

/* PRODUCT VIEW */

.productView .loadingCnt {
    grid-template-columns: 1fr;
}

.productViewSkeleton .pvsImage {
    margin-bottom: 30px;
}

.productViewSkeleton .pvsImage > .skeletonWrapper {
    margin-bottom: 15px;
}

.productViewSkeleton .pvsImage .skeleton.gridImage {
    height: 500px;
}

.productViewSkeleton .pvsAct {
    display: grid;
    grid-template-columns: 2fr 2fr;
    gap: 15px;
    width: 60%;
    margin: 30px auto;
}

.productViewSkeleton .pvsInfo {
    margin: 20px 0;
}

.productViewSkeleton .pvsInfo .skeletonWrapper {
    width: 80%;
    margin: 10px 0;
}

.productViewSkeleton .pvsInfo .skeletonWrapper:nth-child(1) {
    width: 20%;
}

.productViewSkeleton .pvsInfo .skeletonWrapper:nth-child(2) {
    width: 40%;
}

.productViewSkeleton .pvsInfo .skeletonWrapper:nth-child(3) {
    width: 30%;
}

.productViewSkeleton .pvsInfo .skeletonWrapper:nth-child(5) {
    width: 45%;
}

.pvLoaderInnerCnt {
    display: grid;
    grid-template-columns: 2fr 2fr;
}

.cart .loadingCnt {
    grid-template-columns: 1fr;
}

/* TABLE */

.tableSkeleton {
    margin-top: 30px;
}

.tableSkeleton .skeletonWrapper {
    box-shadow: none;
}

.tableSkeleton table {
    table-layout: fixed;
}

.tableSkeleton table thead th {
    border-top: none;
    border-width: 1px;
}

.tableSkeleton table tbody td {
    border: none;
}

.tableSkeleton table thead th,
.tableSkeleton table tbody td {
    vertical-align: middle;
}

.tableSkeleton table thead th:first-child,
.tableSkeleton table tbody td:first-child {
    width: 50px;
}

.tableSkeleton table thead th:first-child .skeletonWrapper,
.tableSkeleton table tbody td:first-child .skeletonWrapper {
    width: 50px;
}

.tableSkeleton table thead th:first-child .skeleton.tableCell,
.tableSkeleton table tbody td:first-child .skeleton.tableCell {
    width: 20px;
}

.tableSkeleton table tbody td:nth-child(2) {
    width: 100px;
}

.tableSkeleton table tbody td:nth-child(2) .skeletonWrapper {
    width: 100px;
}

.tableSkeleton table tbody td .skeleton.tableCell {
    height: 30px;
    width: 150px;
}

.tableSkeleton table tbody td:nth-child(1) .skeleton.tableCell {
    height: 25px;
    width: 25px;
}

.tableSkeleton table tbody td:nth-child(2) .skeleton.tableCell {
    height: 70px;
    width: 80px;
}

.tableSkeleton table tbody td:last-child .skeleton.tableCell {
    height: 50px;
    width: 100px;
}

/* SIGNUP */
.signupSkeleton .ssFields {
    display: grid;
    grid-template-columns: 1fr 1fr;
}
